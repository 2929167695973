html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@-ms-viewport {
  width: device-width;
}

html {
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #2a3134;
  background-color: #fbfcfd;
  margin: 0;
}

[tabindex='-1']:focus {
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  cursor: help;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 600;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

a {
  color: #258df2;
  text-decoration: none;
  transition: all 0.3s ease;
}
a:focus,
a:hover {
  color: #cc3600;
  text-decoration: none;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
}

[role='button'] {
  cursor: pointer;
}

a,
area,
button,
[role='button'],
input,
label,
select,
summary,
textarea {
  touch-action: manipulation;
}

table {
  border-collapse: collapse;
  background-color: transparent;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #636c72;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: left;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
textarea {
  line-height: inherit;
}

input[type='radio']:disabled,
input[type='checkbox']:disabled {
  cursor: not-allowed;
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
  -webkit-appearance: listbox;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
}

input[type='search'] {
  -webkit-appearance: none;
}

output {
  display: inline-block;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: #2a3134;
}

h1,
.h1 {
  font-size: 2.5rem;
}

h2,
.h2 {
  font-size: 2rem;
}

h3,
.h3 {
  font-size: 1.75rem;
}

h4,
.h4 {
  font-size: 1.5rem;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

h4,
h5,
h6 {
  font-weight: 400;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: normal;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled,
.feature-lists {
  padding-left: 0;
  list-style: none;
}

.list-inline,
.small-social {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 5px;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  border-left: 0.25rem solid #eceeef;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #636c72;
}
.blockquote-footer::before {
  content: '\2014 \00A0';
}

.blockquote-reverse {
  padding-right: 1rem;
  padding-left: 0;
  text-align: right;
  border-right: 0.25rem solid #eceeef;
  border-left: 0;
}

.blockquote-reverse .blockquote-footer::before {
  content: '';
}

.blockquote-reverse .blockquote-footer::after {
  content: '\00A0 \2014';
}

.btn {
  display: inline-block;
  font-weight: normal;
  line-height: 1.25;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border-radius: 3px;
  transition: all 0.2s ease-in-out 0.3s ease;
}
.btn:focus,
.btn:hover {
  text-decoration: none;
}
.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(255, 68, 0, 0.25);
}
.btn.disabled,
.btn:disabled {
  cursor: not-allowed;
  opacity: 0.65;
}
.btn:active,
.btn.active {
  background-image: none;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.an-circle-icon-btn {
  position: relative;
  width: 35px;
  height: 35px;
  background: rgba(251, 252, 253, 0.7);
  color: #2a3134;
  border: 0;
  outline: none;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  -webkit-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  -moz-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
}
.an-circle-icon-btn.notification {
  background: rgba(253, 186, 44, 0.3);
  color: #fdba2c;
}
.an-circle-icon-btn.notification:hover,
.an-circle-icon-btn.notification:focus {
  background: rgba(253, 186, 44, 0.4);
  color: #fdba2c;
  outline: 0;
}
.an-circle-icon-btn.primary {
  background: rgba(37, 141, 242, 0.3);
  color: #258df2;
}
.an-circle-icon-btn.primary:hover,
.an-circle-icon-btn.primary:focus {
  background: rgba(37, 141, 242, 0.4);
  color: #258df2;
}

.an-btn-primary {
  background-color: #258df2 !important;
}
.an-btn-primary button {
  background-color: #258df2 !important;
}

.btn-primary {
  color: #fff;
  background-color: #ff4400;
  border-color: #ff4400;
}
.btn-primary:hover {
  color: #fff;
  background-color: #cc3600;
  border-color: #c23400;
}
.btn-primary:focus,
.btn-primary.focus {
  box-shadow: 0 0 0 2px rgba(255, 68, 0, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #ff4400;
  border-color: #ff4400;
}
.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #cc3600;
  background-image: none;
  border-color: #c23400;
}

.btn-secondary {
  color: #292b2c;
  background-color: #fff;
  border-color: #ccc;
}
.btn-secondary:hover {
  color: #292b2c;
  background-color: #e6e6e6;
  border-color: #adadad;
}
.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #fff;
  border-color: #ccc;
}
.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  color: #292b2c;
  background-color: #e6e6e6;
  background-image: none;
  border-color: #adadad;
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #2aabd2;
}
.btn-info:focus,
.btn-info.focus {
  box-shadow: 0 0 0 2px rgba(91, 192, 222, 0.5);
}
.btn-info.disabled,
.btn-info:disabled {
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #31b0d5;
  background-image: none;
  border-color: #2aabd2;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #419641;
}
.btn-success:focus,
.btn-success.focus {
  box-shadow: 0 0 0 2px rgba(92, 184, 92, 0.5);
}
.btn-success.disabled,
.btn-success:disabled {
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #449d44;
  background-image: none;
  border-color: #419641;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #eb9316;
}
.btn-warning:focus,
.btn-warning.focus {
  box-shadow: 0 0 0 2px rgba(240, 173, 78, 0.5);
}
.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #ec971f;
  background-image: none;
  border-color: #eb9316;
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}
.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #c12e2a;
}
.btn-danger:focus,
.btn-danger.focus {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5);
}
.btn-danger.disabled,
.btn-danger:disabled {
  background-color: #d9534f;
  border-color: #d9534f;
}
.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #c9302c;
  background-image: none;
  border-color: #c12e2a;
}

.btn-outline-primary {
  color: #ff4400;
  background-image: none;
  background-color: transparent;
  border-color: #ff4400;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #ff4400;
  border-color: #ff4400;
}
.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: 0 0 0 2px rgba(255, 68, 0, 0.5);
}
.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: #ff4400;
  background-color: transparent;
}
.btn-outline-primary:active,
.btn-outline-primary.active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #ff4400;
  border-color: #ff4400;
}

.btn-outline-secondary {
  color: #ccc;
  background-image: none;
  background-color: transparent;
  border-color: #ccc;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}
.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5);
}
.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #ccc;
  background-color: transparent;
}
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #ccc;
  border-color: #ccc;
}

.btn-outline-info {
  color: #5bc0de;
  background-image: none;
  background-color: transparent;
  border-color: #5bc0de;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}
.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: 0 0 0 2px rgba(91, 192, 222, 0.5);
}
.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #5bc0de;
  background-color: transparent;
}
.btn-outline-info:active,
.btn-outline-info.active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #5bc0de;
  border-color: #5bc0de;
}

.btn-outline-success {
  color: #5cb85c;
  background-image: none;
  background-color: transparent;
  border-color: #5cb85c;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}
.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: 0 0 0 2px rgba(92, 184, 92, 0.5);
}
.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #5cb85c;
  background-color: transparent;
}
.btn-outline-success:active,
.btn-outline-success.active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #5cb85c;
  border-color: #5cb85c;
}

.btn-outline-warning {
  color: #f0ad4e;
  background-image: none;
  background-color: transparent;
  border-color: #f0ad4e;
}
.btn-outline-warning:hover {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}
.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: 0 0 0 2px rgba(240, 173, 78, 0.5);
}
.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #f0ad4e;
  background-color: transparent;
}
.btn-outline-warning:active,
.btn-outline-warning.active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #f0ad4e;
}

.btn-outline-danger {
  color: #d9534f;
  background-image: none;
  background-color: transparent;
  border-color: #d9534f;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}
.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: 0 0 0 2px rgba(217, 83, 79, 0.5);
}
.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: #d9534f;
  background-color: transparent;
}
.btn-outline-danger:active,
.btn-outline-danger.active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-link {
  font-weight: normal;
  color: #ff4400;
  border-radius: 0;
}
.btn-link,
.btn-link:active,
.btn-link.active,
.btn-link:disabled {
  background-color: transparent;
}
.btn-link,
.btn-link:focus,
.btn-link:active {
  border-color: transparent;
}
.btn-link:hover {
  border-color: transparent;
}
.btn-link:focus,
.btn-link:hover {
  color: #b33000;
  text-decoration: underline;
  background-color: transparent;
}
.btn-link:disabled {
  color: #636c72;
}
.btn-link:disabled:focus,
.btn-link:disabled:hover {
  text-decoration: none;
}

.btn-lg {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type='submit'].btn-block,
input[type='reset'].btn-block,
input[type='button'].btn-block {
  width: 100%;
}

.readmin-page-content {
  position: relative;
  width: 100%;
  min-height: 100%;
  /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh;
  background-color: #f6f6f6;
  padding-top: 40px;
  transition: all 0.3s ease;
}
@media (max-width: 520px) {
  .readmin-page-content {
    padding-top: 55px;
  }
}
.readmin-page-content.menu-open {
  padding-left: 256px;
}
@media (max-width: 991px) {
  .readmin-page-content.menu-open {
    padding-left: 30px;
  }
}

.error-notify {
  font-size: 0.8em;
  text-align: center;
}

.readmin-form {
  position: relative;
  width: 100%;
}
@media (max-width: 560px) {
  .readmin-form.inline .inline-btn {
    margin-top: 20px;
  }
}
.readmin-form input {
  width: 100%;
}
.readmin-form label {
  margin: 0 !important;
}

.an-header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: #fff;
  padding: 0 20px;
  z-index: 1300;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: flex-end;
  -webkit-box-shadow: 0 1px 5px 0 rgba(38, 50, 56, 0.1);
  -moz-box-shadow: 0 1px 5px 0 rgba(38, 50, 56, 0.1);
  box-shadow: 0 1px 5px 0 rgba(38, 50, 56, 0.1);
  height: 50px;
}
@media (max-width: 520px) {
  .an-header {
    z-index: 1400;
  }
}
.an-header .brand {
  position: relative;
  color: #fff;
  font-size: 1.5rem;
}
.an-header .brand img {
  width: 130px;
}
.an-header .header-right {
  display: flex;
  align-items: center;
  justify-content: center;
}
.an-header .header-right > a {
  margin: 0 10px 0 15px;
}
.an-header .header-right button {
  padding: 0 !important;
}
.an-header .header-left {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.an-header .header-left .brand {
  margin-right: 70px;
}

.menubtn {
  padding: 0;
  background: transparent;
  cursor: pointer;
  outline: 0;
}
.menubtn i {
  transition: all 0.3s ease;
}
.menubtn:hover i {
  color: #258df2;
}

.material-icons {
  color: #757575;
}

.slide-content {
  position: fixed;
  top: 75px;
  right: -300px;
  width: 300px;
  background: #fff;
  height: calc(100% - 55px);
  padding: 0;
  padding-bottom: 10px;
  overflow: scroll;
  border-top: 2px solid #ff4400;
  -webkit-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  -moz-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  transition: all 0.3s ease;
  z-index: 1500;
  cursor: default;
}
.slide-content.toggle {
  right: 0;
}
.slide-content h6 {
  padding-bottom: 20px;
}

.notification-single {
  position: relative;
  width: 100%;
  padding-left: 45px;
  padding-bottom: 20px;
}
.notification-single .avatar {
  background-position: center center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
  border-radius: 50%;
  -webkit-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  -moz-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
}
.notification-single .avatar.avatar-icon {
  background: rgba(37, 141, 242, 0.3);
  color: #258df2;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  -moz-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
}
.notification-single .avatar.success {
  background: rgba(64, 199, 65, 0.3);
  color: #40c741;
}
.notification-single .avatar.warning {
  background: rgba(253, 186, 44, 0.3);
  color: #fdba2c;
}
.notification-single .avatar.danger {
  background: rgba(230, 64, 67, 0.3);
  color: #e64043;
}
.notification-single .details {
  color: #63737b;
  font-size: 14px;
  margin-bottom: 8px;
}
.notification-single .details a {
  color: #2a3134;
  padding-right: 5px;
}
.notification-single .details a:hover {
  color: #258df2;
}
.notification-single .time {
  font-size: 12px;
  color: #98a6ac;
  margin: 0;
}

.readmin-sidebar {
  position: relative;
}
.readmin-sidebar .menudivider {
  padding: 15px 0;
}
.readmin-sidebar .menu-drawer > div {
  padding-top: 75px;
}
.readmin-sidebar .menu-drawer.has-bg .nav-menu a,
.readmin-sidebar .menu-drawer.has-bg .nav-menu div,
.readmin-sidebar .menu-drawer.has-bg .nav-menu svg,
.readmin-sidebar .menu-drawer.has-bg .nav-menu i {
  color: #1b202a !important;
  opacity: 0.97;
}
.readmin-sidebar .menu-drawer.has-bg .nav-menu i {
  opacity: 0.7;
}
.childmenu .readmin-sidebar .menu-drawer.has-bg .nav-menu .childmenu .active {
  background: rgba(42, 49, 52, 0.3) !important;
}
.readmin-sidebar .menu-drawer.has-bg .nav-menu .active {
  background: rgba(42, 49, 52, 0.3) !important;
}
.readmin-sidebar .menu-drawer.has-bg .nav-menu .menudivider hr {
  background-color: #1b202a !important;
  opacity: 0.3;
}
.readmin-sidebar .an-header {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 14000;
  background: #258df2;
  transition: all 0.3s ease;
}
@media (max-width: 520px) {
  .readmin-sidebar .an-header {
    width: auto;
  }
}
@media (max-width: 520px) {
  .readmin-sidebar .an-header .brand {
    font-size: 1rem;
    margin-right: 30px;
  }
}

.sidebar-initial-color {
  background-color: #ffffff;
}

.nav-menu .childmenu + div {
  padding: 0 !important;
}

.childmenu .nav-menu .childmenu .active {
  background: #258df2 !important;
  color: #fff !important;
}

.nav-menu .childmenu .active i {
  color: #fff !important;
}

.nav-menu .active {
  background: #258df2 !important;
  color: #fff !important;
}
.nav-menu .active i {
  color: #fff !important;
}

.iconbox-wrapper {
  position: relative;
  width: 100%;
}
.iconbox-wrapper.alter {
  background: #fff;
  padding: 30px;
  box-shadow: 0 4px 16px -2px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.02);
  border-radius: 3px;
  margin-bottom: 30px;
}
@media (ma-width: 520px) {
  .iconbox-wrapper.alter {
    padding: 15px;
  }
}
.iconbox-wrapper.alter .col-md-3:last-child .iconbox-single {
  border-right: 0;
}
.iconbox-wrapper.alter .iconbox-single {
  box-shadow: none;
  border-right: 1px solid #eaeff5;
  margin: 0;
  padding-left: 15px;
}
@media (max-width: 768px) {
  .iconbox-wrapper.alter .iconbox-single {
    border-right: 0;
  }
}
.iconbox-wrapper.alter .iconbox-single .circle-icon-alter {
  border: 1px solid #eaeff5;
  position: relative;
  width: 60px;
  height: 60px;
  margin-right: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconbox-single {
  position: relative;
  background: #fff;
  padding: 30px;
  margin-bottom: 30px;
  border-radius: 3px;
  box-shadow: 0 4px 16px -2px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.02);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (ma-width: 520px) {
  .iconbox-single {
    padding: 10px;
  }
}
.iconbox-single .circle-icon {
  position: relative;
  width: 50px;
  height: 50px;
  margin-right: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconbox-single .circle-icon .material-icons {
  color: #fff;
}
.iconbox-single .box-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconbox-single h5 {
  margin: 0;
}
.iconbox-single p {
  margin: 0;
  opacity: 0.6;
}

.readmin-panel {
  position: relative;
  width: 100%;
  background: #fff;
  margin-bottom: 30px;
  border-radius: 3px;
  box-shadow: 0 4px 16px -2px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.02);
}
.readmin-panel.righticonmenu .panel-heading {
  padding: 5px 30px;
  padding-right: 15px;
}
.readmin-panel.body-text-center .panel-body {
  text-align: center;
}
.readmin-panel .panel-heading {
  position: relative;
  width: 100%;
  border-bottom: 1px solid #eaeff5;
  padding: 17px 30px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.readmin-panel .panel-heading h5 {
  margin: 0;
}
.readmin-panel .panel-body {
  padding: 20px;
  width: 100%;
  display: inline-block;
}

.secondary-appbar {
  background: #40c741 !important;
}

.table thead th {
  border-top: 0;
}

.product-table tbody tr td {
  vertical-align: middle;
}

.panel-body .recharts-wrapper {
  width: 100% !important;
}
.panel-body .recharts-wrapper .recharts-surface {
  width: 100%;
}
.panel-body .recharts-wrapper .recharts-legend-wrapper .recharts-surface {
  width: auto;
}

.panel-body .recharts-responsive-container {
  height: 300px !important;
}

.grid-example {
  position: relative;
  padding: 30px;
}
.grid-example .row {
  margin-bottom: 15px;
}

.grid-example .row > .col,
.grid-example .row > [class^='col-'] {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: rgba(86, 61, 124, 0.15);
  border: 1px solid rgba(86, 61, 124, 0.2);
}

.pt20 {
  padding-top: 20px;
}

.pb20 {
  padding-bottom: 20px;
}

.product-table-img {
  max-width: 50px;
  max-height: 50px;
}

.pulse_loader {
  animation: rotator 1.4s linear infinite;
}
.pulse_loader .path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.4s ease-in-out infinite, colors 5.6s ease-in-out infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}

@keyframes colors {
  0% {
    stroke: #4285f4;
  }
  25% {
    stroke: #de3e35;
  }
  50% {
    stroke: #f7c223;
  }
  75% {
    stroke: #1b9a59;
  }
  100% {
    stroke: #4285f4;
  }
}

@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}

.Tour_content-wrapper {
  display: flex;
}
.Tour_content-wrapper .Tour_wrapper-indicator {
  font-size: 24px;
  margin-right: 16px;
}
.Tour_content-wrapper .Tour_wrapper-header {
  max-width: 280px;
}
.Tour_content-wrapper .Tour_wrapper-header .Tour_wrapper-header_title {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #fff;
  margin-bottom: 8px;
  margin-top: 6px;
}
.Tour_content-wrapper .Tour_wrapper-header .Tour_wrapper-header_text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  margin-bottom: 0px;
  color: #fff;
}
.Tour_content-wrapper .Tour_wrapper-header .Tour_wrapper-header_text b {
  font-weight: 500;
}

.readmin-products {
  position: relative;
  width: 100%;
}

.product-single {
  position: relative;
  width: 100%;
  padding: 20px;
  padding-bottom: 30px;
  background: #fff;
  text-align: center;
  box-shadow: 0 4px 16px -2px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.02);
  border-radius: 3px;
  margin-bottom: 30px;
}
.product-single img {
  height: 240px;
  margin: 0 auto;
}
.product-single .favorite {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
}
.product-single h5 {
  padding-top: 10px;
  padding-bottom: 5px;
}
.product-single .price {
  margin: 0;
  color: #258df2;
  padding-bottom: 10px;
}

.table-responsive table {
  min-width: 500px;
}

.review-item {
  position: relative;
  width: 100%;
  margin-bottom: 30px;
}
.review-item.totals {
  background: #f4f7fa;
  padding: 30px;
}
.review-item.totals ul {
  margin-bottom: 0;
}
.review-item h6 {
  text-transform: uppercase;
  padding-bottom: 15px;
  margin: 0;
  letter-spacing: 1px;
  font-weight: bold;
}
.review-item ul {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 15px;
  position: relative;
  margin-bottom: 45px;
}
.review-item ul li {
  padding: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.review-item ul li span {
  opacity: 0.7;
  padding-right: 30px;
}
.review-item ul li strong {
  font-weight: normal;
}

.re-page-banner {
  position: relative;
  height: 250px;
  margin: -30px;
  margin-top: -35px;
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.re-page-banner .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9;
  transition: all 0.3s ease;
}
.re-page-banner h1 {
  position: relative;
  z-index: 20;
  color: #fff;
  margin: 0;
  font-size: 3.25rem;
  font-weight: 400;
}

.about-box-single {
  position: relative;
  margin-bottom: 30px;
}
.about-box-single p {
  opacity: 0.8;
}

.inner-box-title {
  position: relative;
  padding: 0 0 15px;
  border-bottom: 2px solid #258df2;
  font-size: 1.25rem;
  text-transform: uppercase;
  margin-top: 0;
}
.inner-box-title.full {
  border-bottom: 0;
}

.an-team-single {
  position: relative;
  width: 100%;
  transition: all 0.3s ease;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  -moz-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
}
.an-team-single:hover {
  -webkit-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  -moz-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
}
.an-team-single:hover .image-container .overlay {
  opacity: 1;
  visibility: visible;
}
.an-team-single.light-bg .user-details {
  background: #fff;
}
.an-team-single.light-bg .user-details h4 a,
.an-team-single.light-bg .user-details p {
  color: #2a3134;
}
.an-team-single .image-container {
  position: relative;
}
.an-team-single .image-container .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  opacity: 0;
  visibility: hidden;
}
.an-team-single .image-container .overlay .small-social li a i {
  font-size: 1.75rem;
}
.an-team-single .image-container .overlay .small-social li a:hover {
  color: #258df2;
}
.an-team-single img {
  width: 100%;
  display: block;
}
.an-team-single .user-details {
  padding: 15px;
  background: #258df2;
}
.an-team-single .user-details h4 {
  margin: 0;
  line-height: 1.5;
  text-transform: uppercase;
  font-size: 1.25rem;
}
.an-team-single .user-details h4 a {
  color: #fff;
  display: block;
}
.an-team-single .user-details p {
  margin: 0;
  color: #2a3134;
  opacity: 0.7;
  font-size: 1rem;
}
.an-team-single .user-details .small-social {
  padding-top: 15px;
}

.small-social {
  margin: 0;
}
.small-social li {
  display: inline-block;
  padding: 7px;
}
.small-social li a {
  color: #fff;
}

.an-services {
  position: relative;
}
.an-services.center-align .an-service-single {
  text-align: center;
}
.an-services.center-align .an-service-single i {
  margin: 0 auto 30px;
}

.an-service-single {
  position: relative;
  margin-bottom: 30px;
  background: #fff;
  padding: 30px;
  text-align: center;
  -webkit-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  -moz-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
}
.an-service-single:hover i {
  color: #258df2;
  border-color: #258df2;
}
.an-service-single i {
  color: #258df2;
  font-size: 2.5rem;
  padding-bottom: 20px;
}
.an-service-single h4 {
  padding-bottom: 15px;
  font-size: 1.25rem;
  margin: 0;
  line-height: 1.5;
}
.an-service-single p {
  color: #63737b;
  margin: 0;
  font-weight: 400;
}

.an-address-single {
  position: relative;
  width: 100%;
}
.an-address-single p {
  padding-left: 30px;
  position: relative;
}
.an-address-single i {
  position: absolute;
  left: 0;
  top: 4px;
  color: #258df2;
}

.contact-form > div {
  margin-bottom: 20px;
}

.blog-single {
  position: relative;
  background: #fff;
  -webkit-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  -moz-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  margin-bottom: 30px;
}
.blog-single img {
  width: 100%;
}
.blog-single .details {
  padding: 20px;
}
.blog-single .details p {
  margin: 0;
  opacity: 0.6;
  color: #2a3134;
}

.an-pricing {
  position: relative;
  width: 100%;
  padding: 45px 0 15px;
}

.an-pricing-table-single {
  position: relative;
  width: 100%;
  background: #fff;
  margin-bottom: 30px;
  border-radius: 3px;
  -webkit-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  -moz-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  transition: all 0.3s ease;
  text-align: center;
  padding: 30px;
  padding-bottom: 0;
}
.an-pricing-table-single .price-header {
  position: relative;
  text-align: center;
}
.an-pricing-table-single .price-header .plan-name {
  margin-top: 0;
}
.an-pricing-table-single .price-header .plan-price {
  color: #258df2;
  margin-bottom: 0;
  font-size: 2.5rem;
}
.an-pricing-table-single .price-header .plan-price span {
  font-size: 1rem;
  position: relative;
  top: -13px;
  padding-right: 5px;
  color: #258df2;
}
.an-pricing-table-single .price-header .plan-price small {
  font-size: 1rem;
  color: #98a6ac;
  display: block;
}
.an-pricing-table-single .price-header p {
  margin: 0;
  text-transform: uppercase;
  font-size: 1rem;
  padding-top: 10px;
}
.an-pricing-table-single .helper-text {
  position: relative;
  padding: 25px 0 0;
  opacity: 0.7;
}

.feature-lists {
  padding: 15px 0;
  text-align: center;
  margin: 0;
}
.feature-lists li {
  padding: 7px 0;
  color: #4c595e;
  border-bottom: 1px solid #eaeff5;
  font-size: -1rem;
}

.price-footer {
  padding: 20px;
  position: relative;
}

.login-wrapper {
  position: relative;
  width: 100%;
  max-width: 400px;
  background: #ff4400;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 60px;
}

.login-logo {
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

.login-fields {
  position: relative;
  width: 100%;
  background: #fff;
  padding: 45px;
  top: 30px;
  right: 30px;
  -webkit-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  -moz-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
}
.login-fields h3 a {
  font-size: 13px;
  font-weight: 400;
  padding-left: 30px;
  color: #2a3134;
  opacity: 0.7;
}
.login-fields h3 a:hover {
  color: #258df2;
}
.login-fields h3 i {
  font-size: 5rem;
}
.login-fields h3.title-404 {
  font-size: 7.5rem;
}

.Checkout {
  margin: 0 auto;
  max-width: 800px;
  box-sizing: border-box;
  padding: 0 5px;
}

.Checkout-admin {
  box-sizing: border-box;
  padding: 10px;
}

.pay-label {
  color: rgba(0, 0, 0, 0.38);
  font-weight: 400;
  font-size: 12px;
  box-sizing: border-box;
  display: block;
}

.pay-button {
  white-space: nowrap;
  border: 0;
  outline: 0;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  padding: 0 14px;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: #fff;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  background-color: #6772e5;
  text-decoration: none;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
  margin-top: 10px;
}

.checkout-form {
  width: 500px;
}

.pay-button:hover {
  color: #fff;
  cursor: pointer;
  background-color: #7795f8;
  transform: translateY(-1px);
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
}

.pay-button:focus {
  outline: none;
}

.StripeElement {
  display: block;
  margin: 10px 0 20px 0;
  max-width: 500px;
  padding: 10px 14px;
  border-bottom: 1px solid #dadada;
  background: white;
}

.StripeElement--focus {
  border-bottom: 1px solid #ff4400;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.PaymentRequestButton {
  padding: 0;
}

.readmin-footer {
  position: relative;
  text-align: center;
  padding: 30px 0;
  padding-bottom: 0;
}
.readmin-footer p {
  opacity: 0.7;
}

.readmin-style-switcher {
  position: fixed;
  right: -350px;
  top: 0;
  background: #fff;
  height: 100%;
  width: 350px;
  -webkit-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  -moz-box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  box-shadow: 0 6px 25px 0 rgba(38, 50, 56, 0.2);
  z-index: 99999;
  padding: 30px;
  transition: all 0.3s ease;
}
.readmin-style-switcher.toggle {
  right: 0;
}
.readmin-style-switcher .clear-btn {
  position: absolute !important;
  right: 0;
  top: 0;
}
.readmin-style-switcher p {
  opacity: 0.6;
  margin-bottom: 15px;
}
.readmin-style-switcher h6 {
  padding-bottom: 15px;
  padding-top: 15px;
  text-transform: uppercase;
}
.readmin-style-switcher .single-radio {
  width: 50%;
  float: left;
}
.readmin-style-switcher .radio-group {
  position: relative;
  display: inline-block;
}
.readmin-style-switcher .radio-group > div {
  width: 40px !important;
  float: left;
  margin-bottom: 10px;
}
.readmin-style-switcher .style-toggle {
  position: absolute;
  top: 150px;
  left: -50px;
  width: 50px;
  height: 50px;
  background: #258df2;
  border-radius: 3px 0 0 3px;
  border: 0;
  outline: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.readmin-style-switcher .style-toggle i {
  color: #fff;
}
.readmin-style-switcher .style-toggle:hover {
  background: #0e80ef;
}
.readmin-style-switcher .overflow-content {
  overflow: auto;
  width: 100%;
  height: 100%;
}

/* Standard syntax */
@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.c-floating-text__div {
  color: rgba(0, 0, 0, 0.38);
  font-size: 12px;
}

.c-info-floating-text__span {
  color: rgba(0, 0, 0, 0.38);
  font-size: 12px;
  font-weight: lighter;
  cursor: pointer;
}

.carrot__icon {
  color: #9e9e9e !important;
}

.server-error__div {
  position: relative;
  font-size: 14px;
  line-height: 14px;
  color: #f44336;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.server-success__div {
  position: relative;
  font-size: 14px;
  line-height: 14px;
  color: #0ad71c;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 20px;
  line-height: 20px;
}

.footer-text__div {
  text-align: center;
}

.footer-text__span {
  color: #2c2c2c;
  font-size: 12px;
  font-weight: bold;
}

.large-icon-popovers__div {
  color: #000000;
  font-size: 90px;
  text-align: center;
}

.medium-icon-popovers__div {
  color: #000000;
  font-size: 54px;
  text-align: center;
}

.onboarding__div {
  height: auto;
}

.onboarding-bg__div {
  background-image: url('../../img/new_bg_coin.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  background-color: #e9ebee;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 40px;
  height: 100%;
}
@media (max-width: 520px) {
  .onboarding-bg__div {
    padding: 15px;
    padding-top: 55px;
  }
}

.vertical-center {
  min-height: 100%;
  /* Fallback for browsers do NOT support vh unit */
  min-height: 90vh;
  /* These two lines are counted as one :-)       */
  display: flex;
  align-items: center;
}

.signup-container__div {
  padding-top: 20px;
  padding-bottom: 20px;
}

.header-signup__div {
  text-align: center;
  color: #2c2c2c;
  font-size: 34px;
  font-weight: bold;
}
@media (max-width: 520px) {
  .header-signup__div {
    font-size: 30px;
  }
}

.header-signup-sml__div {
  text-align: center;
  color: #2c2c2c;
  font-size: 30px;
  font-weight: lighter;
}
@media (max-width: 520px) {
  .header-signup-sml__div {
    font-size: 20px;
  }
}

.subheader-signup__div {
  text-align: center;
  color: #2c2c2c;
  font-weight: lighter;
  font-size: 18px;
  line-height: 30px;
}
@media (max-width: 520px) {
  .subheader-signup__div {
    font-size: 16px;
  }
}

.subheader-signup-sml__div {
  text-align: center;
  color: #2c2c2c;
  font-size: 16px;
}
@media (max-width: 520px) {
  .subheader-signup-sml__div {
    font-size: 16px;
  }
}

.subheader-signup__span {
  font-weight: bold;
}

.header-signup-bold__div {
  text-align: center;
  color: #2c2c2c;
  font-size: 40px;
  font-weight: bold;
}

.header2-signup__div {
  text-align: center;
  color: #2c2c2c;
  font-size: 40px;
}

.subheader2-signup__div {
  text-align: center;
  color: #2c2c2c;
  font-size: 20px;
  font-style: italic;
}

.signup-form__div {
  align-content: center;
  width: 300px;
  margin-top: 30px;
}

.new-signup-form__div {
  align-content: center;
  width: 350px !important;
  margin-top: 20px;
}

.new-subText-signup__div {
  text-align: center;
  color: #9a9a9a;
  font-weight: lighter;
  font-size: 12px;
  margin-top: 10px;
  padding: 20px;
}

.new-terms-privacy-policy__a {
  text-decoration: underline;
  cursor: pointer;
  color: #9a9a9a;
  font-size: 12px;
}

.new-form-box__div {
  position: relative;
  background: #fff;
  padding: 10px;
  margin-bottom: 30px;
  border-radius: 1px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.24), 0 0 1px 0 rgba(0, 0, 0, 0.12);
  border: solid 0.3px transparent;
}

.signup-details-form__div {
  align-content: center;
  width: 430px;
  margin-top: 30px;
}

.subText-signup__div {
  text-align: center;
  color: #9a9a9a;
  font-size: 16px;
  margin-top: 10px;
}

.subText-resend-link__div {
  text-align: center;
  color: #9a9a9a;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}

.forgot-password__Link {
  color: #4a90e2;
  font-size: 12px;
  font-weight: lighter;
  text-decoration: underline;
}

.signup-details-form__div {
  align-content: center;
  width: 320px;
  margin-top: 30px;
}

.terms-privacy-policy__a {
  text-decoration: underline;
  cursor: pointer;
  color: #9a9a9a;
  font-size: 16px;
}

.promo-code-set__div {
  margin-left: 0px !important;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  width: 100%;
  height: 40px;
  padding: 10px 10px 10px 0px;
  border: none;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  left: 10px;
  width: 200px;
  border: 1px solid #eaeff5;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.inputContainer {
  position: relative;
}

.add-gc__button {
  cursor: pointer;
  border: none;
  background-color: transparent;
}

.add-gc__button:focus {
  outline: none;
}

.add-gc__label {
  padding: 1px 7px 6px;
}

.add-gif__img {
  width: 20px;
  height: 8px;
}

.add-image__img {
  cursor: pointer;
  width: 20px;
  height: 19px;
}

.add-emoji__img {
  width: 20px;
  height: 20px;
}

.add-hash__img {
  width: 20px;
  height: 19px;
}

.add-users__img {
  width: 20px;
  height: 12px;
}

.media-remove__img {
  width: 20px;
  height: 20px;
}

.add-buttons__div {
  display: flex;
  justify-content: flex-end;
}

.gc-button__div {
  margin-left: 10px;
  margin-right: 10px;
}

.g-comment-button__div {
  display: flex;
  justify-content: flex-end;
}

.media__div {
  width: inherit;
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
}

.media-cropper__div {
  max-width: 500px;
  min-height: 400px;
}

.media-remove__button {
  background: transparent;
  position: absolute;
  right: 5px;
  top: 5px;
}

.media__Image {
  display: block;
  max-width: 600px;
  max-height: 300px;
  width: auto;
  height: auto;
}
@media (max-width: 991px) {
  .media__Image {
    max-width: 500px;
    max-height: 250px;
  }
}
@media (max-width: 576px) {
  .media__Image {
    max-width: 300px;
    max-height: 150px;
  }
}

.media-give-carrots__Image {
  width: inherit;
}

.give-carrots-autocomplete-main__div {
  position: relative;
  width: 100%;
}

.give-carrots-autocomplete__div {
  flex: flex-grow;
  min-width: 280px;
}

.give-carrots-autocomplete-sub__div {
  width: 100%;
  display: inline-block;
  position: relative;
}

.give-carrots-autocomplete__hr {
  border-top: none #e0e0e0;
  border-left: none #e0e0e0;
  border-right: none #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  bottom: 0px;
  box-sizing: content-box;
  margin: 0px;
  position: absolute;
  width: 100%;
}

.give-carrots-autocomplete-nofocus__hr {
  border-top: none #eb8921;
  border-left: none #eb8921;
  border-right: none #eb8921;
  border-bottom: 2px solid #eb8921;
  bottom: 0px;
  box-sizing: content-box;
  margin: 0px;
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.give-carrots-autocomplete-focus__hr {
  border-top: none #eb8921;
  border-left: none #eb8921;
  border-right: none #eb8921;
  border-bottom: 2px solid #eb8921;
  bottom: 0px;
  box-sizing: content-box;
  margin: 0px;
  position: absolute;
  width: 100%;
  transform: scaleX(1);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.autocomplete-user-fullname__span {
  padding: 0;
  font-size: 12px;
  color: #2c2c2c;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.autocomplete-user-username__span {
  font-size: 15px;
  color: #e2e2e2;
}

.autocomplete-user-username-focus__span {
  font-size: 15px;
  color: #e2e2e2;
}

.autocomplete-user__div {
  flex-flow: row wrap;
  display: flex;
}

.badge-selector__div {
  width: 320px;
  height: 230px;
  padding: 10px;
}

.badge-selector-body__div {
  display: flex;
  flex-wrap: wrap;
}

.badge-selector-item__div {
  height: 90px;
  padding: 10px;
  position: relative;
  cursor: pointer;
}

.badge-selector__Img {
  width: 54px;
  height: 54px;
}

.badge-selector-name__div {
  color: #4b4f56;
  font-size: 8px;
  font-weight: 400;
  text-align: center;
  margin-top: 10px;
  word-wrap: break-word;
  width: 54px;
}

.badge-selected-image__div {
  width: 60px;
  height: 61px;
  position: absolute;
  top: 7px;
  left: 7px;
}

.textarea__input {
  width: 100%;
}

.textarea__input__input::-webkit-input-placeholder {
  color: #e2e2e2 !important;
}

.textarea__input__input:-moz-placeholder {
  color: #e2e2e2 !important;
}

.textarea__input__input::-moz-placeholder {
  color: #e2e2e2 !important;
}

.textarea__input__input:-ms-input-placeholder {
  color: #e2e2e2 !important;
}

.redeem__div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 25px;
}

.balance-carrots {
  display: flex;
  flex-direction: row;
  font-size: 34px;
  color: #2c2c2c;
  align-items: center;
}

.balance-carrot__img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.balance-carrot-large__img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-top: 30px;
}

.feed-card-header {
  display: flex;
  padding: 16px;
  position: relative;
}

.feed-card-title {
  display: inline-block;
  vertical-align: top;
  padding-right: 45px;
  margin-left: 16px;
}
@media (max-width: 576px) {
  .feed-card-title {
    padding-right: 45px;
  }
}

.feed-card-header-text {
  flex-flow: row wrap;
  display: flex;
  align-items: baseline;
}

.feed-carrot-value {
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 4px;
  color: black;
  font-size: 18px;
  font-weight: bold;
}

.feed-card-message {
  font-weight: 400;
  font-size: 14px;
  color: #2c2c2c;
  padding: 16px;
  padding-top: 0px;
  line-height: 24px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.feed-card-hashtag {
  font-weight: 400;
  font-size: 14px;
  color: #0ad71c;
  line-height: 24px;
}

.feed-card-mention {
  font-weight: 400;
  font-size: 14px;
  background-color: #fff2e8;
  color: #ff4400;
  line-height: 24px;
  padding: 0 2px;
  border-radius: 2px;
  cursor: pointer;
}

.noEvent {
  cursor: text;
}

.autocomplete-user-fullname__span {
  padding-left: 8px;
  font-size: 12px;
  color: #2c2c2c;
  font-weight: 600;
  letter-spacing: 0.2px;
}

.feed-card-arrow__img {
  width: 8px;
  height: 12px;
  margin: 0px 7px 0px;
}

.feed-card-badge__img {
  width: 20px;
  height: 20px;
  margin: 0px 3px 0px;
  white-space: normal;
}

.feed-card-from {
  white-space: normal;
  font-weight: 600;
  font-size: 14px;
  color: #2c2c2c;
}

.feed-card-gave {
  white-space: normal;
  font-weight: 400;
  font-size: 14px;
  color: #2c2c2c;
}

.feed-card-anniversary {
  white-space: normal;
  font-weight: 600;
  font-size: 14px;
}

.feed-card-to {
  white-space: normal;
  font-weight: 600;
  font-size: 14px;
  color: #2c2c2c;
}

.feed-carrot-time {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}

.feed-card-carrots {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin: 16px 0px 16px;
  padding: 12px;
  color: #2c2c2c;
}

.feed-card-carrrot__img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-top: 3px;
}

.feed-action__div {
  padding: 0px 14px 6px;
  display: flex;
  justify-content: flex-start;
}

.feed-comment-action__div {
  margin-left: 5px;
}

.feed-comment__button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.feed-comment__button:focus {
  outline: none;
}

.feed-favourite__img {
  width: 18px;
  height: 16px;
}

.feed-comment__img {
  width: 20px;
  height: 20px;
}

.feed-media__div {
  display: flex;
  justify-content: space-around;
  width: inherit;
  margin-bottom: 10px;
}

.feed-image__div {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 10px;
}

.feed-end__div {
  width: 100%;
  text-align: center;
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 10px;
}

.carrotHR-feed__span {
  font-weight: bold;
  font-size: 14px;
  color: #000000;
}

.username-clickable__span {
  cursor: pointer;
  color: #2c2c2c;
}

.username-clickable-to__span {
  color: #ff4400;
  cursor: pointer;
}

.deactivated_user {
  color: #bfbfbf !important;
  cursor: text;
}

.users-list-container__div {
  position: relative;
  cursor: pointer;
  color: #2c2c2c;
}

.users-list-hover__div {
  position: absolute;
  width: 300px;
  top: 20px;
  left: -10px;
  z-index: 99;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
  background-color: white;
  transition: transform 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms,
    opacity 250ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
  border-radius: 2px;
  overflow-y: auto;
}

.post-home-link__span {
  padding: 15px;
  padding-left: 5px;
  font-size: 14px;
  font-weight: normal;
  color: #4a90e2;
  cursor: pointer;
}

.draftJsMentionPlugin__mention__29BEd,
.draftJsMentionPlugin__mention__29BEd:visited {
  color: #ff4400;
  cursor: pointer;
  display: inline-block;
  background: #fff2e8;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 2px;
  text-decoration: none;
}

.draftJsMentionPlugin__mention__29BEd:hover,
.draftJsMentionPlugin__mention__29BEd:focus {
  color: #ff4400;
  background: #fff2e8;
  outline: 0;
  /* reset for :focus */
}

.draftJsMentionPlugin__mention__29BEd:active {
  color: #222;
  background: #455261;
}

.draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm {
  /*suggestionsEntry*/
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.4s cubic-bezier(0.27, 1.27, 0.48, 0.56);
}

.draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm:active {
  background-color: #cce7ff;
}

.draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd {
  background-color: #f8f8f8;
}

.draftJsMentionPlugin__mentionSuggestionsEntryText__3Jobq {
  display: inline-block;
  margin-left: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 368px;
  font-size: 0.9em;
  margin-bottom: 0.2em;
}

.draftJsMentionPlugin__mentionSuggestionsEntryAvatar__1xgA9 {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 12px;
}

.draftJsMentionPlugin__mentionSuggestions__2DWjA {
  /* border: 1px solid #eee; */
  /* margin-top: 0.4em; */
  position: absolute;
  /* min-width: 220px; */
  /* max-width: 440px; */
  padding: 0;
  margin: 0;
  background: #fff;
  border-radius: 2px;
  /* box-shadow: 0px 4px 30px 0px rgba(220,220,220,1); */
  /* padding-top: 8px;
    padding-bottom: 8px; */
  /* padding: 0; */
  /* height: auto; */
  max-height: 300px;
  overflow: auto;
  min-width: 248px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 12px;
  z-index: 999;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  box-sizing: border-box;
}

/* .editor .public-DraftEditorPlaceholder-root {
    color: #999;
    position: absolute;
  } */
/*PLACEHOLDER STYLE STARTS*/
.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  height: inherit;
  text-align: initial;
}

.public-DraftEditor-content[contenteditable='true'] {
  -webkit-user-modify: read-write-plaintext-only;
}

.DraftEditor-root {
  position: relative;
}

.DraftEditor-editorContainer {
  color: rgba(0, 0, 0, 0.87);
  background-color: rgba(255, 255, 255, 0);
  border-left: 0.1px solid transparent;
  position: relative;
  z-index: 1;
}

[class^='gc-comment-text__div_'] .DraftEditor-editorContainer {
  /*comments*/
  font-size: 14px;
  padding: 7px 0;
}

.public-DraftEditor-block {
  position: relative;
}

.DraftEditor-alignLeft .public-DraftStyleDefault-block {
  text-align: left;
}

.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
  left: 0;
  text-align: left;
}

.DraftEditor-alignCenter .public-DraftStyleDefault-block {
  text-align: center;
}

.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.DraftEditor-alignRight .public-DraftStyleDefault-block {
  text-align: right;
}

.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
  right: 0;
  text-align: right;
}

.public-DraftEditorPlaceholder-root {
  /*placeholder*/
  color: #e2e2e2;
  position: absolute;
  z-index: 1;
}

[class^='gc-comment-text__div_'] .public-DraftEditorPlaceholder-root {
  font-size: 14px;
  padding: 7px 0;
}

.public-DraftEditorPlaceholder-hasFocus {
  color: #e2e2e2;
}

.DraftEditorPlaceholder-hidden {
  display: none;
}

.public-DraftStyleDefault-block {
  position: relative;
  white-space: pre-wrap;
  /* display: inline-block; */
  /* white-space: nowrap; */
}

.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
}

.public-DraftStyleDefault-rtl {
  direction: rtl;
  text-align: right;
}

.public-DraftStyleDefault-listLTR {
  direction: ltr;
}

.public-DraftStyleDefault-listRTL {
  direction: rtl;
}

.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 16px 0;
  padding: 0;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 1.5em;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
  margin-right: 1.5em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 3em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
  margin-right: 3em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
  margin-left: 4.5em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
  margin-right: 4.5em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
  margin-left: 6em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
  margin-right: 6em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
  margin-left: 7.5em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
  margin-right: 7.5em;
}

.public-DraftStyleDefault-unorderedListItem {
  list-style-type: square;
  position: relative;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
  list-style-type: disc;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
  list-style-type: circle;
}

.public-DraftStyleDefault-orderedListItem {
  list-style-type: none;
  position: relative;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  left: -36px;
  position: absolute;
  text-align: right;
  width: 30px;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
  position: absolute;
  right: -36px;
  text-align: left;
  width: 30px;
}

.public-DraftStyleDefault-orderedListItem:before {
  content: counter(ol0) '. ';
  counter-increment: ol0;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
  content: counter(ol1, lower-alpha) '. ';
  counter-increment: ol1;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
  content: counter(ol2, lower-roman) '. ';
  counter-increment: ol2;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
  content: counter(ol3) '. ';
  counter-increment: ol3;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
  content: counter(ol4, lower-alpha) '. ';
  counter-increment: ol4;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
  counter-reset: ol0;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
  counter-reset: ol1;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
  counter-reset: ol2;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
  counter-reset: ol3;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
  counter-reset: ol4;
}

.comment-card-div {
  padding: 0 16px 16px;
  position: relative;
}

.gc-drop-div {
  margin-left: 10px;
}

[class^='gc-comment-text__div'] {
  position: relative;
}

.gc-comment-counter__div {
  position: absolute;
  right: 0px;
  bottom: -20px;
  color: rgba(0, 0, 0, 0.38);
  font-size: 12px;
  font-family: Roboto, sans-serif;
}

.comments-card-div {
  padding: 0 16px 0 16px;
  position: relative;
  background-color: #fff;
  border-top: 1px solid #dedede;
}

.comment-card-right__div {
  padding-top: 16px;
  margin-left: 10px;
}

.comment-card-from__div {
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
}

.comment-card-from__span {
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  align-items: center;
}

.comment-card-message__div {
  margin-top: 3px;
  font-weight: 400;
  font-size: 14px;
  color: #2c2c2c;
}

.comment-card-carrrot__img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  margin-top: -3px;
}

.comment-action__div {
  padding-top: 5px;
  display: flex;
  justify-content: flex-start;
}

.comment-action__button {
  border: none;
  cursor: pointer;
  background-color: #f4f4f4;
}

.comment-action__button:focus {
  outline: none;
}

.comment-reply__img {
  width: 18px;
  height: 15px;
}

.comment-time__div {
  display: inline-block;
  position: absolute;
  top: 16px;
  right: 10px;
  color: rgba(0, 0, 0, 0.38);
  font-size: 12px;
  text-align: right;
}

.comments-more__button {
  padding: 10px 16px 10px;
  position: relative;
  background-color: #fff;
  border: none;
  border-top: 1px solid #dedede;
  height: 48px;
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.comments-more__button:focus {
  outline: none;
}

.comment-more-drop__div {
  display: inline-block;
  position: absolute;
  top: 0px;
  right: 0px;
}

.comment-more__img {
  width: 12px;
  height: 8px;
}

.commentUser {
  display: flex;
  align-items: center;
}

.commentUserName {
  font-size: 16px;
  font-weight: normal;
}

.commentUserName b {
  font-weight: normal;
}

.commentUserId {
  font-size: 12px;
  font-weight: normal;
}

.right-cloumn-box {
  position: relative;
  background: #ffffff;
  border: 0.5px solid #eeeeee;
  border-radius: 2px;
  padding: 20px;
  margin-bottom: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.right-cloumn-box-without-top-radius {
  position: relative;
  background: #fff;
  padding: 20px;
  margin-bottom: 10px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  -webkit-box-shadow: 0 4px 16px -2px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(0, 0, 0, 0.02);
  box-shadow: 0 4px 16px -2px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.02);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.points-to-give-refresh-body__div {
  padding-left: 10px;
}

.points-to-give-refresh__div {
  color: #2c2c2c;
  font-size: 12px;
  font-weight: bold;
}

.points-to-give-date__div {
  color: #2c2c2c;
  font-size: 11px;
}

.user-profile-name__div {
  font-size: 24px;
  font-weight: bold;
  color: #4a4a4a;
  text-align: center;
}

.user-profile-header__div {
  font-weight: bold;
  color: #4a4a4a;
  font-size: 16px;
}

.user-profile-content__div {
  display: flex;
  flex-direction: row;
  font-size: 34px;
  color: #4a4a4a;
  margin-top: 10px;
  align-items: center;
}

.user-profile-container-main__div {
  position: relative;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.user-profile-container__img {
  position: absolute;
  left: 0;
}

.user-profile-container-main-value__div {
  width: inherit;
  padding-left: 35px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
}

.home-footer-link__a {
  cursor: pointer;
  color: #c0c0c0;
  font-size: 12px;
}

.home-footer-row__div {
  padding: 0px 30px;
}

.home-footer-text__span {
  color: #9b9b9b;
  font-size: 8px;
}

.home-sticky-right-rail {
  position: sticky !important;
}

.right-cloumn-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
}

.right-cloumn-header-text {
  font-weight: bold;
  font-size: 16px;
  color: #2c2c2c;
  flex-grow: 1;
}

.options-drop__div {
  display: inline-block;
  position: absolute;
  top: 2px;
  right: -10px;
}

.top-receivers-carrots {
  display: flex;
  position: absolute;
  top: 5;
  right: 0;
}

.top-receivers-carrots-text__div {
  color: #212121;
  font-size: 14px;
  font-weight: bold;
}

.top-receivers-name__div {
  color: #2c2c2c;
  font-size: 14px;
  padding-right: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.top-receivers-name-clickable__div {
  cursor: pointer;
}

.top-receivers-row__div {
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 5px;
  padding-top: 5px;
  position: relative;
}

.top-receivers-noname__div {
  color: #2c2c2c;
  font-size: 16px;
}

.top-receivers-noname-container__div {
  opacity: 0.2;
  padding-top: 20px;
  padding-bottom: 20px;
}

.top-badges-names__div {
  display: flex;
  flex-wrap: wrap;
}

.top-badges__img {
  width: 20px;
  height: 20px;
  margin-right: 5px;
}

.home-main__div {
  position: relative;
}

.home-sidebar-fixed__div {
  position: fixed !important;
  right: 0;
}

.home-sticky__div {
  position: sticky;
}

.home-left-col {
  position: absolute !important;
  top: 0px;
  bottom: 0;
  left: 0;
  overflow-y: scroll;
}

.home-right-col {
  position: absolute !important;
  top: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
}

.setup-bg__div {
  background-position: top center;
  background-color: #e9ebee;
  padding-top: 20px;
}

.setup-bg-full__div {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 100%;
  background-position: top center;
  background-color: #e9ebee;
}

.setup-allowance-hint__div {
  color: rgba(0, 0, 0, 0.38);
  font-size: 16px;
}

.setup-choose__div {
  align-content: center;
  width: 750px;
  margin-top: 30px;
}

.setup-reivew-employee__div {
  align-content: center;
  width: 750px;
  margin-top: 30px;
}

.setup-container__div {
  border: 1px solid #d9d9d9;
  border-image: initial;
  overflow: hidden;
  padding: 40px 10px;
}

.subheader-setup__div {
  text-align: center;
  color: #2c2c2c;
  font-size: 16px;
  font-weight: bold;
}

.list-setup__div {
  color: #2c2c2c;
  font-size: 12px;
  margin-top: 5px;
}

.plan-list-setup__div {
  color: #2c2c2c;
  font-size: 12px;
}

.phonenumber-text-setup__div {
  text-align: center;
  color: #2c2c2c;
  font-size: 12px;
  margin-top: 5px;
}

.phonenumber-setup__div {
  text-align: center;
  color: #2c2c2c;
  font-size: 20px;
  font-weight: bold;
  padding-top: 10px;
  padding-bottom: 10px;
}

.setup-review-choose__div {
  align-content: center;
  width: 700px;
  margin-top: 30px;
}

.rabbit-setup__img {
  width: 54px;
  height: 54px;
}

.carrot-setup__img {
  width: 51px;
  height: 54px;
  margin-left: -20px;
}

.setup-review-right-border {
  border-right: 1px solid #d9d9d9;
  border-image: initial;
  overflow: hidden;
}

.subheader-setup-review__div {
  text-align: left;
  color: #2c2c2c;
  font-size: 24px;
  font-weight: 500;
}

.price-setup-review__div {
  color: #2c2c2c;
  font-weight: 500;
}

.dollar-setup-review__span {
  font-size: 24px;
}

.amount-setup-review__span {
  font-size: 54px;
}

.text-setup-review__span {
  font-size: 14px;
}

.preview-calculator-reivew__div {
  margin-top: 20px;
  padding: 10px;
  background-color: #eeeeef;
}

.setup-review-bg__div {
  background-position: top center;
  background-color: #e9ebee;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding-bottom: 60px;
}

.setup-review__div {
  padding-bottom: 75px;
}

.setup-needhelp__div {
  text-align: center;
  color: #2c2c2c;
  font-size: 16px;
  font-weight: 500;
}

.bottom-bar-setup {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background: #fff;
  padding: 10px 20px;
  z-index: 1300;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: flex-end;
  height: 65px;
}

.bottom-bar-reivew-buttons__div {
  width: 700px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.credit-card-setup__img {
  width: 70px;
  height: 56px;
  margin-top: 9px;
  margin-bottom: 9px;
}

.ach-setup__img {
  width: 67px;
  height: 74px;
}

.top-buffer-row {
  margin-top: 20px;
}

.bottom-buffer-row {
  margin-bottom: 20px;
}

.top-buffer-row-small {
  margin-top: 10px;
}

.top-buffer-new-row {
  margin-top: 40px;
}

.top-buffer-table-row {
  margin-top: 20px;
}

.payment-options__span {
  color: #2c2c2c;
  font-weight: 500;
  font-size: 24px;
}

.card-deatils__div {
  color: #9a9a9a;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
}

.bold-text__span {
  color: #2c2c2c;
  font-weight: bold;
  font-size: 24px;
}

.csv-setup__img {
  width: 84px;
  height: 100px;
}

.emp-manual-setup__img {
  width: 107px;
  height: 94px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.import-subtext__div {
  color: #4a4a4a;
  font-size: 12px;
  text-align: center;
  padding-top: 10px;
}

.import-subtext-download__span {
  color: #4a90e2;
  font-size: 12px;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

.add-employee__button {
  border: none;
  height: 88px;
  width: 100%;
  background-color: #eeeeef;
  color: #9b9b9b;
  font-size: 24px;
  font-weight: 500;
  outline: none;
}

.add-employeebutton:focus {
  outline: none !important;
}

.edit-employee__img {
  height: 18px;
  width: 18px;
  background-color: transparent;
}

.edit-employee__button {
  background-color: transparent;
  border: none;
}

.edit-employee__button:focus {
  outline: none;
}

.user-table-header {
  font-size: 12px;
  font-weight: lighter;
  color: #9b9b9b;
  text-align: center;
  word-break: break-all;
}

.user-table-value {
  font-size: 14px;
  font-weight: 400;
  color: #9b9b9b;
}

.carrot-success__img {
  width: 150px;
  height: 150px;
}

.upload_bulk_btn__img {
  width: 192px;
  height: 36px;
  cursor: pointer;
}

.setup-credit-top-error__div {
  margin-top: 5px;
  margin-bottom: 10px;
}

.added-employees-header__div {
  color: #2c2c2c;
  font-size: 16px;
  font-weight: 500;
}

.iconbox-single-review {
  box-shadow: none;
  border-right: 1px solid #eaeff5;
  margin: 0;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 4px 16px -2px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.02);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.download-bulk__button {
  border: none;
  width: 215px;
  height: 36px;
  background-color: transparent;
  cursor: pointer;
}

.download-bulk__button:focus {
  outline: none;
  cursor: pointer;
}

.download_bulk__img {
  width: 215px;
  height: 36px;
}

.setup-allowance-drop__div {
  padding: 10px;
}

.setup-allowances-preview-head__div {
  color: #212121;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
}

.setup-allowances-preview-sub__div {
  color: #212121;
  font-weight: lighter;
  font-size: 16px;
  text-align: center;
}

.btn-apply-promo {
  background: none;
  color: #ff4400;
  border: 2px solid #ff4400;
  border-radius: 4px;
  text-shadow: none;
  text-align: center;
  padding: 10px 15px;
  margin-left: 10px;
  margin-top: 20px;
  cursor: pointer;
  outline: none;
}
.btn-apply-promo:hover {
  background: #ff4400;
  color: #fff;
}
.btn-apply-promo:active {
  outline: none;
}
.btn-apply-promo:focus {
  outline: none;
}

.admin-content-container__div {
  position: relative;
  width: 100%;
  background: #fff;
  padding: 25px;
  border-radius: 2px;
  -webkit-box-shadow: 0 4px 16px -2px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(0, 0, 0, 0.02);
  box-shadow: 0 4px 16px -2px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.02);
}

.admin-fixed-width__div {
  overflow-x: auto;
}

.acc-with-bottom-bar__div {
  margin-bottom: 75px;
}

.admin-breadcrumps-primary__span {
  color: #1f1f1f;
  font-size: 22px;
}

.admin-breadcrumps-primary__link {
  color: #1f1f1f;
  font-size: 22px;
  text-decoration: underline;
}

.admin-breadcrumps-secondary__span {
  color: #1f1f1f;
  font-size: 22px;
  font-weight: bold;
}

.admin-user-signup-status-pending {
  color: #ff6060 !important;
}

.admin-user-signup-status-approved {
  color: #0ad71c !important;
}

.admin-employee-action__button {
  background-color: transparent;
  border: none;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  cursor: pointer;
}

.admin-employee-action__button:focus {
  outline: none;
}

.admin-employee-action-delete__icon {
  height: 18px;
  width: 14px;
  background-color: transparent;
}

.admin-reminder-buttons__div {
  margin-top: 10px;
}

.admin-pagination-text__div {
  color: #727272;
  font-size: 13px;
  text-align: right;
}

.page-arrow__button {
  background-color: transparent;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: none;
}

.page-arrow__button:focus {
  outline: none;
}

.page-arrow-right__button {
  background-color: transparent;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border: none;
  margin-left: 20px;
}

.page-arrow-right__button:focus {
  outline: none;
}

.page-arrow__img {
  width: 8px;
  height: 12px;
  vertical-align: top;
}

.bottom-bar-admin {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  background: #f6f6f6;
  padding: 10px 20px;
  z-index: 900;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: flex-end;
  height: 65px;
  padding-left: 276px;
}
@media (max-width: 991px) {
  .bottom-bar-admin {
    padding-left: 30px;
  }
}

.bottom-bar-admin-buttons__div {
  display: flex;
  width: 100%;
  padding-left: 20px;
  padding-right: 105px;
  flex-grow: 1;
  align-items: center;
}

.bottom-bar-needhelp__div {
  color: #2c2c2c;
  font-size: 12px;
  font-weight: 500;
  min-width: 300px;
  flex: 1;
}

.btn-contact-advisor {
  background: none;
  color: #ff4400;
  border: 2px solid #ff4400;
  border-radius: 4px;
  text-shadow: none;
  text-align: center;
  padding: 10px 15px;
  margin-left: 10px;
  cursor: pointer;
  outline: none;
}
.btn-contact-advisor:hover {
  background: #ff4400;
  color: #fff;
}
.btn-contact-advisor:active {
  outline: none;
}
.btn-contact-advisor:focus {
  outline: none;
}

.btn-contact-advisor-small {
  background: none;
  color: #ff4400;
  border: 2px solid #ff4400;
  border-radius: 4px;
  text-shadow: none;
  text-align: center;
  padding: 8px 12px;
  cursor: pointer;
  outline: none;
  font-size: 14px;
}
.btn-contact-advisor-small:hover {
  background: #ff4400;
  color: #fff;
}
.btn-contact-advisor-small:active {
  outline: none;
}
.btn-contact-advisor-small:focus {
  outline: none;
}

.admin-bottom-bar-pagination__div {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.admin-bottom-bar-pagination-arrows__div {
  margin-left: 20px;
}

.carrot-add-container__button {
  background-color: transparent;
  border: none;
}

.carrot-add-container__button:focus {
  outline: none;
}

.cac-bottom__div {
  color: rgba(0, 0, 0, 0.38);
  font-size: 8px;
  font-style: italic;
  font-weight: lighter;
}

.carrot-add-container {
  padding: 15px;
}

.carrot-input-file__div {
  padding-top: 10px;
}

.carrot-add-toggle-container {
  padding-left: 15px;
  padding-top: 10px;
}

.carrot-add-container-main__div {
  position: relative;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}

.carrot-chip-text-container-main__div {
  position: relative;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 10px;
}

.carrot-chip-text-internal__div {
  display: flex;
  flex-wrap: wrap;
}

.carrot-add-container-main-value__div {
  width: inherit;
  padding: 5px;
  padding-left: 35px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
}

.carrot-add-container-no-carrot__div {
  width: inherit;
  padding: 5px;
  padding-left: 0px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
}

.carrot-add-container-main__hr {
  border-top: none #e0e0e0;
  border-left: none #e0e0e0;
  border-right: none #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  bottom: 2px;
  box-sizing: content-box;
  margin: 0px;
  margin-top: 2px;
  margin-bottom: 2px;
  position: absolute;
  width: 100%;
}

.carrot-add-container__img {
  position: absolute;
  left: 0;
  top: 15px;
}

.carrot-add-container-drop__img {
  position: absolute;
  left: 0;
  top: 15px;
}

.carrot-add-container-drop__svg-img {
  position: absolute;
  right: 0;
  top: 12px;
  color: rgba(0, 0, 0, 0.87);
  fill: inherit;
  height: 24px;
  width: 24px;
  user-select: none;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.carrot-chip-textfield__input {
  flex: 2;
  border: none;
  background: transparent;
}

.carrot-chip-textfield__input:focus {
  outline: none;
}

.carrot-settings-button__div {
  margin-right: 30px;
}

.increase-decrease-allowance__img {
  width: 90px;
  height: 90px;
  margin: 20px;
}

.increase-decrease-allowance-table__div {
  margin-top: 5px;
  padding: 10px;
  background-color: #f9f9f9;
}

.allowance-change-table-header__div {
  color: #212121;
  font-size: 12px;
  text-align: left;
}

.allowance-change-table-body__div {
  color: rgba(0, 0, 0, 0.38);
  font-size: 14px;
  text-align: left;
}

.allowance-change-table-body-right__div {
  color: #212121;
  font-size: 14px;
}

.allowance-change-table-body-right-bold__div {
  color: #212121;
  font-size: 14px;
  font-weight: bold;
}

.allowance-change-carrots {
  display: flex;
  flex-direction: row;
}

.allowance-change-carrrot__img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.row-container__div {
  padding-top: 10px;
}

.container-container__div {
  padding: 20px;
}

.share-link__span {
  color: #1f1f1f;
  font-size: 14px;
  font-weight: bold;
}

.share-link-value__span {
  color: #4a90e2;
  font-size: 14px;
  margin-right: 20px;
}

.user-table-header-admin {
  font-size: 12px;
  font-weight: lighter;
  color: #9b9b9b;
}

.user-table-value-admin {
  font-size: 14px;
  font-weight: 400;
  color: #9b9b9b;
}

.remove_user__img {
  width: 84px;
  height: 87px;
  margin: 20px;
}

.remove-users__span {
  color: rgba(0, 0, 0, 0.38);
  font-size: 16px;
}

.remove-users-italics__span {
  color: rgba(0, 0, 0, 0.38);
  font-style: italic;
  font-weight: lighter;
  font-size: 16px;
}

.remind_user__img {
  width: 90px;
  height: 90px;
  margin: 20px;
}

.remind-user-detail__span {
  color: rgba(0, 0, 0, 0.87);
  font-size: 16px;
}

.subheader-admin-rewards__div {
  text-align: left;
  color: #2c2c2c;
  font-size: 16px;
}

.subheader-admin-rewards-block__div {
  display: block !important;
}

.subheader-admin-rewards-bold__span {
  color: #2c2c2c;
  font-size: 16px;
  font-weight: bold;
}

.subheader__div {
  margin-top: 15px;
}

.payment-options-subtext__span {
  color: #2c2c2c;
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
}

.preview-calculator-admin__div {
  margin-top: 20px;
  padding: 10px;
  background-color: #f9f9f9;
}

.price-setup-admin__div {
  color: #2c2c2c;
  font-weight: 400;
}

.price-setup-admin-header__span {
  font-weight: bold;
  font-size: 12px;
}

.credit-card-rewards-review__img {
  width: 20px;
  height: 16px;
  margin-top: 3px;
}

.credit-card-rewards-review__img {
  width: 20px;
  height: 16px;
  margin-top: 3px;
}

.ach-rewards-review__img {
  width: 19px;
  height: 21px;
}

.payment-review-type__span {
  color: #2c2c2c;
  font-size: 16px;
  font-weight: bold;
  margin-left: 5px;
}

.bank-details-rewards__span {
  color: #9a9a9a;
  font-size: 14px;
}

.rewards-review-content-value {
  margin-top: 2px;
}

.rewards-review-content-container {
  padding-top: 15px;
  padding-left: 15px;
}

.rewards-country-drop__div {
  position: absolute;
  right: 0;
  top: 30;
}

.payment-review-card-carrrot__img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
}

.payments-rewards-note__span {
  color: #747474;
  font-size: 8px;
  line-height: 150%;
}

.payments-rewards-note-header__span {
  font-weight: bold;
}

.payments-rewards-container__div {
  border: 1px solid #d9d9d9;
  border-image: initial;
  overflow: hidden;
  padding: 10px 10px;
}

.payments-rewards__Link {
  color: #4a90e2;
  font-size: 16px;
}

.no-payments__div {
  color: white;
  font-size: 16px;
  padding: 5px 10px;
  border-radius: 2px;
  display: flex;
  align-content: center;
}

.no-payments__link {
  color: white;
  font-size: 16px;
  text-decoration: underline;
  cursor: pointer;
}

.warning-div-alert {
  background-color: #ff6060;
}

.warning-div-info {
  background-color: #4a90e2;
}

.no-payments__img {
  width: 22px;
  height: 19px;
}

.no-payments-img__div {
  margin: 10px;
  align-self: center;
}

.topbar-catalog__div {
  display: flex;
  width: 100%;
}

.topbar-catalog-button__div {
  align-self: center;
}

.topbar-add-all-button__div {
  margin-left: 10px;
}

.rewards-row-container__div {
  display: flex;
  position: relative;
  padding: 15px 15px 0px 15px;
  width: 100%;
  min-height: 100px;
}

.rewards-row__img {
  width: 72px;
}

.custom-rewards-row__img {
  width: 72px;
  border-radius: 5px;
}

.rewards-row-body__div {
  padding: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  color: #727272;
  font-size: 13px;
  width: 100%;
}

.rewards-row-bottom__div {
  margin-left: 0px !important;
}

.rewards-row-header__span {
  font-size: 16px;
  width: 100%;
}

.rewards-carrot-quantity__span {
  margin-left: 3px;
}

.rewards-quantity__div {
  margin-left: 10px;
  width: auto !important;
}

.rewards-custom-actions-container__div {
  position: relative;
}

.rewards-custom-actions__div {
  position: absolute;
  right: 0;
  bottom: 0;
}

.rewards-select-denominations__img {
  width: 18px;
  height: 12px;
}

.rewards-select-denominations__button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.rewards-select-denominations__button:focus {
  outline: none;
}

.rewards-row-toggle__div {
  position: absolute;
  right: 20px;
  bottom: -5px;
}

.rewards-set-allowance-hint__div {
  color: rgba(0, 0, 0, 0.38);
  font-weight: lighter;
  font-size: 12px;
  padding-top: 10px;
}

.rewards-totals-to__div {
  align-items: flex-end;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
}

.diabled-gift-card__div {
  opacity: 0.25;
}

.custom-rewards-add-image__img {
  width: 88px;
  height: 88px;
}

.custom-rewards-add-image__label {
  border-radius: 4px;
  overflow: hidden;
  position: relative;
}

.cr-overlay__div {
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
}
.cr-overlay__div:hover {
  opacity: 1;
}

.cr-overlay__i {
  fill: white;
  color: white;
}

.subscription-row__div {
  padding-top: 10px;
}

.subscription-subtitle__span {
  color: #727272;
  font-weight: bold;
  font-size: 14px;
}

.subscription-title__span {
  color: #4a4a4a;
  font-weight: bold;
  font-size: 16px;
}

.subscription-subtext__span {
  color: #727272;
  font-weight: 400;
  font-size: 14px;
}

.subscription-disclaimer__span {
  color: #9b9b9b;
  font-weight: 400;
  font-size: 12px;
}

.rewards-current-balance__span {
  color: #4a4a4a;
  font-weight: bold;
  font-size: 14px;
}

.rewards-current-balance-value__span {
  color: #4a4a4a;
  font-weight: 400;
  font-size: 14px;
}

.subscription-body__span {
  color: #4a4a4a;
  font-weight: 400;
  font-size: 14px;
}

.subscription-body-trial__span {
  color: #d0021b;
  font-weight: 400;
  font-size: 14px;
}

.subscription-cancel__a {
  color: #4a90e2;
  font-weight: bold !important;
  font-size: 14px;
}

.subscription-cancel__a:visited {
  color: #4a90e2;
  font-weight: bold;
  font-size: 14px;
}

.redeem-header__div {
  color: #4a4a4a;
  font-size: 16px;
  font-weight: bold;
}

.redeem-search-bar__div {
  display: flex;
  align-items: center;
}

.redeem-container__div {
  position: relative;
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 3px;
  -webkit-box-shadow: 0 4px 16px -2px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(0, 0, 0, 0.02);
  box-shadow: 0 4px 16px -2px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.02);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.redeem-grid-cell-column__div {
  padding-left: 5px !important;
  padding-right: 5px !important;
  margin-bottom: 10px !important;
}

.redeem-grid-cell__div {
  position: relative;
  height: 97%;
  background: #fff;
  padding: 10px;
  margin: 2px;
  border-radius: 3px;
  -webkit-box-shadow: 0 4px 16px -2px rgba(0, 0, 0, 0.1),
    0 0 0 1px rgba(0, 0, 0, 0.02);
  box-shadow: 0 4px 16px -2px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.02);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  overflow: visible;
  min-height: 220px;
}

.redeem-list-header__div {
  align-items: center;
}

.redeem-list-header__span {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
  text-align: center;
}

.redeem-list-header-small__span {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.redeem-reward__img {
  width: 120px;
  height: 76px;
}

.redeem-top-buffer__div {
  margin-top: 15px;
}

.cashout-top-buffer__div {
  margin-top: 5px;
}

.redeem-info__div {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 24px;
  margin-right: 4px;
  cursor: pointer;
}

.redeem-hover__div {
  background-color: rgba(0, 0, 0, 0.87);
  position: absolute;
  right: 0;
  top: 215px;
  z-index: 999;
  color: white;
  padding: 5px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
}

.redeem-hover-bold__span {
  color: white;
  font-size: 12px;
  font-weight: 500;
}

.redeem-no-carrots-info__div {
  background-color: rgba(0, 0, 0, 0.87);
  position: absolute;
  left: 0;
  bottom: 40px;
  z-index: 999;
  color: white;
  padding: 5px;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 400;
}

.redeem-row__img {
  width: 130px;
  height: 82px;
}

.custom-redeem-row__img {
  width: 82px;
  height: 82px;
  border-radius: 5px;
}

.custom-redeem-row-full__img {
  width: 120px;
  height: 120px;
  border-radius: 5px;
}

.redeem-disclaimer__div {
  margin-top: 30px;
  margin-bottom: 30px;
  color: #9e9e9e;
  font-size: 16px;
}

.cashout-terms__div {
  color: #4a4a4a;
  font-size: 12px;
}

.cashout-bottom-message__div {
  color: rgba(0, 0, 0, 0.38);
  font-size: 12px;
  font-weight: 400;
}

.cashout-bottom-message__p {
  color: rgba(0, 0, 0, 0.38);
  font-size: 12px;
  font-weight: 400;
  text-align: center;
}

.cashout-bottom-important__span {
  font-weight: bold;
}

.cashout-top-buffer__div {
  margin-top: 15px;
}

.cashout-bottom-disclaimer__p {
  color: rgba(0, 0, 0, 0.38);
  font-size: 12px;
  font-weight: 400;
  text-align: left;
}

.cashout-bottom-disclaimer__a {
  color: #4a90e2;
}

.cashout-bottom-disclaimer__a:visited {
  color: #4a90e2;
}

.cashout-temp-email__div {
  padding-bottom: 15px;
}

.cashout-temp-email__icon {
  margin-right: 10px;
}

.cashout-carrots__img {
  width: 51px;
  height: 54px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.redeem-not-enough-carrots__div {
  color: #ff6060;
  font-size: 12px;
}

.cashout-email-subtext__div {
  color: #9e9e9e;
  font-size: 12px;
  font-style: italic;
  font-weight: lighter;
}

.cashout-email__div {
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 500;
}

.cashout-reward-label__div {
  color: #4a4a4a;
  font-size: 14px;
  font-weight: 400;
}

.cashout-reward-text__div {
  color: #4a4a4a;
  font-size: 14px;
  font-weight: bold;
}

.cashout-reward-url__div {
  color: #4a90e2;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.cashout-reward-barcode__div {
  display: block;
  max-width: 100px;
  max-height: 50px;
  width: auto;
  height: auto;
}

.cashout-reward-redeem-instruction__div {
  color: #4a4a4a;
  font-size: 12px;
  font-weight: 400;
}
.cashout-reward-redeem-instruction__div a {
  color: #4a90e2;
  font-size: 12px;
  font-weight: bold;
}

.nopage-bg__div {
  background-color: #e9ebee;
  position: fixed;
  top: 40px;
  right: 0;
  bottom: 0;
  left: 0;
}

.somethingwentwrong-bg__div {
  background-color: #e9ebee;
  position: fixed;
  top: 0px;
  right: 0;
  bottom: 0;
  left: 0;
}

.nopage-header__div {
  text-align: center;
  color: #57585d;
  font-size: 74px;
  font-weight: bold;
}

.nopage-subheader__div {
  text-align: center;
  color: #2c2c2c;
  font-size: 34px;
  font-weight: bold;
}

.nopage-body__div {
  color: #2c2c2c;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
}

.nobrowser-subheader__div {
  text-align: center;
  color: #311900;
  font-size: 34px;
  font-weight: bold;
}

.nobrowser-body__div {
  text-align: center;
  color: #311900;
  font-size: 18px;
  font-weight: lighter;
}

.nobrowser-name__div {
  text-align: center;
  color: #311900;
  font-size: 24px;
  font-weight: 500;
}

.nobrowser-version__div {
  text-align: center;
  color: #311900;
  font-size: 14px;
  font-weight: 400;
}

.nobrowser-col__div {
  width: 100px;
  margin: 20px;
}

.nopage-redirect__Link {
  font-weight: bold;
  font-size: 34px;
  text-align: center;
  text-decoration-line: underline;
  color: #ff4400;
}

.nopage-redirect__Link:hover {
  color: #ff4400;
}

.bg-404__div {
  width: 195px;
  height: 195px;
}

.sww-broken-carrot__img {
  width: 150px;
  height: 156px;
}

.browser-logo__img {
  width: 100px;
  height: 100px;
}

.server-success__div {
  width: 100%;
  position: relative;
  display: flex;
  align-self: flex-start;
  font-size: 14px;
  line-height: 14px;
  color: #0ad71c;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

.carrot-header {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: #fff;
  padding: 10px 20px;
  z-index: 1300;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  justify-content: flex-end;
  -webkit-box-shadow: 0 1px 5px 0 rgba(38, 50, 56, 0.1);
  -moz-box-shadow: 0 1px 5px 0 rgba(38, 50, 56, 0.1);
  box-shadow: 0 1px 5px 0 rgba(38, 50, 56, 0.1);
  height: 50px;
}
@media (max-width: 520px) {
  .carrot-header {
    z-index: 1400;
  }
}

.user-drop-down__div {
  display: flex;
  align-items: center;
  cursor: pointer;
  min-width: 168px;
  justify-content: space-between;
}

.username-drop-down__span {
  padding: 0 10px;
  font-size: 16px;
  font-weight: bold;
  color: #2c2c2c;
  text-transform: none;
  line-height: 28px;
  letter-spacing: 0.5px;
}

.sign-in-btn__div {
  background: none;
  color: #0ad71c;
  border: 2px solid #0ad71c;
  border-radius: 4px;
  padding: 11px 27px;
}

.sign-in-btn__div:hover,
.sign-in-btn__div:focus,
.sign-in-btn__div:active,
.sign-in-btn__div.active {
  background: #0ad71c;
  color: #fff;
}

.drop-material-icons {
  color: black;
}

.signin-header-link__span {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 500;
}

.header-img__div {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.header-img-nonotif__div {
  height: 100%;
  width: 48px;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-right: 24px;
}

.navbar-brand__div {
  display: flex;
  align-items: center;
}

.settings-header__div {
  color: #000000;
  font-size: 24px;
  font-weight: bold;
  text-align: left;
}

.settings-account-lock__icon {
  position: absolute;
  right: 0;
  top: 12px;
  cursor: default;
}

.lock-hover__div {
  background-color: rgba(0, 0, 0, 0.87);
  position: absolute;
  right: -8px;
  width: 200px;
  top: 40px;
  z-index: 999;
  color: white;
  padding: 5px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
}

.info-container__div {
  position: relative;
}

.info-hover__div {
  background-color: rgba(0, 0, 0, 0.87);
  position: absolute;
  right: -210px;
  width: 200px;
  top: -40px;
  z-index: 999;
  color: white;
  padding: 5px;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
}

.account-image-floating__div {
  color: rgba(0, 0, 0, 0.38);
  font-size: 16px;
}

.profile-setup__Image {
  width: 75px;
  height: 75px;
}

.settings-add-image__label {
  border-radius: 44px;
  overflow: hidden;
  position: relative;
}

.settings-notif-header__div {
  text-align: right;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
}
@media (max-width: 720px) {
  .settings-notif-header__div {
    text-align: left;
  }
}

.settings-my-carrots__div {
  padding: 10px;
  background: #f6f6f6;
  border-radius: 4px;
}

.settings-my-carrots-header__div {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #4a4a4a;
  font-size: 16px;
}

.settings-my-carrots-content__div {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  display: flex;
  flex-direction: row;
  font-size: 54px;
  color: #4a4a4a;
  margin-top: 20px;
  align-items: center;
}

.notification-item__div {
  position: relative;
  width: 100%;
  display: flex;
  padding: 15px;
  cursor: pointer;
}

.notification__div {
  background: #fff;
}

.notification__div:hover {
  background: #d8d8d8;
}

.notification-item-image__div {
  width: 40px;
  height: 40px;
}

.notification-time__span {
  color: rgba(0, 0, 0, 0.54);
  font-size: 12px;
  margin-top: 3px;
}

.notification-details__div {
  padding-left: 10px;
  line-height: 18px;
}

.notification-details-header__span {
  color: #2c2c2c;
  font-weight: bold;
  font-size: 14px;
}

.notification-details-body__span {
  color: #2c2c2c;
  font-weight: 400;
  font-size: 14px;
}

.notification-bg {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.54);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: opacity;
  transform: translateZ(0px);
  transition: left 0ms cubic-bezier(0.23, 1, 0.32, 1) 0ms,
    opacity 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  z-index: 1400;
  cursor: pointer;
}

.no-notification-bg {
  opacity: 0;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 10px 10px;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.35s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 26px;
  height: 17px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}
.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  width: 26px;
  height: 3px;
  background-color: #404040;
  border-radius: 1px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before,
.hamburger-inner::after {
  content: '';
  display: block;
}
.hamburger-inner::before {
  top: -7px;
}
.hamburger-inner::after {
  bottom: -7px;
}

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease,
    transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease,
    transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}
